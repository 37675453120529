<template>
  <div class="text-center">
    <div>
        <button class="btn btn-default text-uppercase w-100" @click.prevent="call">
            <i class="la la-bullhorn"></i> Panggil
        </button>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from '../../../../repositories/RepositoryFactory';
import SweetAlertTemplate from "./../../../_general/SweetAlert";
import BlockUI from "../../../_js/BlockUI";
const QueueResourceRepository = RepositoryFactory.get('queueResource')
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
  data() {
    return {

    };
  },
  props: {
    queueUserVue: { type: Object },
    rowData: { type: Object }
  },
  methods: {
      call(){
          var vx = this;
          const queueDevice = JSON.parse(sessionStorage.getItem('queueDevice'))
          const payload = {
              note: vx.rowData.note,
              queueId: vx.rowData.id,
              uniqueId: queueDevice.uniqueId
          }
          QueueResourceRepository.postCallResource(payload)
            .then(response => {
              QueueResourceRepository.postRecallQueue({
                queueId: payload.queueId
              })
              new Promise((resolve, reject) => {
                vx.queueUserVue.fetch(resolve);
              }).then((response) => {
                let instance = new SweetAlert().showInfo({
                  swalTitle: "Berhasil!",
                  swalText: "Data Antrian Pasien berhasil diupdate.",
                  swalType: "success",
                  onConfirmButton: function () {
                    blockUI.unblockPage();
                  }
                });
              });
            })
            .catch(error => {
              let instance = new SweetAlert().showInfo({
                swalTitle: "Gagal!",
                swalText: "Data Antrian Pasien gagal diupdate.",
                swalType: "error",
                onConfirmButton: function () {
                  blockUI.unblockPage();
                }
              });
            });
      },
  }
};
</script>