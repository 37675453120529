<template>
  <div
    :id="modalHistory"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">History</h5>
          <button type="button" class="close" data-dismiss="modal" @click="close"></button>
        </div>
        <div class="modal-body">
            <div
              v-if="showLoading"
              class="my-auto p-5 d-flex flex-row h-100"
            >
              <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
              <h5 class="my-auto pl-5">Loading ...</h5>
            </div>
            <div v-if="isLoaded">
                <data-tables :table="table"></data-tables>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="close">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { RepositoryFactory } from "./../../../../repositories/RepositoryFactory";
const QueueUserRepository = RepositoryFactory.get("queueUser");
import SweetAlertTemplate from "./../../../_general/SweetAlert";
const SweetAlert = Vue.extend(SweetAlertTemplate);
import BlockUI from "./../../../_js/BlockUI";
const blockUI = new BlockUI();
import DataTables from "./../../../_table/DataTables";
import BtnAction from "./BtnAction";
export default {
  data() {
    var vx = this;
    return {
    isLoaded: false,
    showLoading: true,
    tableName: 'table_queue_user',
    table: {
        order: [[1, "desc"], [2, "desc"]],
        tableName: "table_queue_user",
        tableDataResult: [],
        tableColumns: [
          {
            title: "ID",
            data: "id"
          },
          {
            title: "Tanggal Kedatangan",
            data: "date"
          },
          {
            title: "Waktu Dibuat",
            data: "createdDate"
          },
          {
            title: "No Antrian",
            data: "queueNoFormatted"
          },
          {
            title: "Display Name 1",
            data: function(row, type) {
                            if(row.queueUnit) {
                                if(row.queueUnit.displayName1){
                                    return row.queueUnit.displayName1
                                }
                            }
                            return ''
                            }
          },
          {
            title: "Display Name 2",
            data: function(row, type) {
                            if(row.queueUnit) {
                                if(row.queueUnit.displayName2){
                                    return row.queueUnit.displayName2
                                }
                            }
                            return ''
                            }
          },
          {
            title: "Tipe Antrian",
            data: "queueType"
          },
          {
            title: "Status",
            data: "queueStatus"
          },
          {
            title: "Actions",
            data: "id",
            createdCell: function (cell, cellData, rowData, rowIndex, colIndex) {
              let btnAction = Vue.extend(BtnAction);
              let instance = new btnAction({
                propsData: {
                  queueUserVue: vx,
                  rowData: rowData
                }
              });
              instance.$mount();
              $(cell)
                .empty()
                .append(instance.$el);
            }
          }
        ],
        tableColumnDefs: [
          {
            targets: [0],
            visible: false
          },
          {
            targets: 1,
            render: function (t, e, a, n) {
              var momentDate = moment(t);
              return momentDate.format("DD/MM/YYYY");
            }
          },
          {
            targets: 2,
            render: function (t, e, a, n) {
              var momentDate = moment(t);
              return momentDate.format("DD/MM/YYYY HH:mm:ss");
            }
          },
          {
            targets: 6,
            render: function (t, e, a, n) {
              var i = {
                AUTO: {
                  title: "Auto",
                  state: "info"
                },
                MANUAL: { title: "Manual", state: "primary" }
              };
              return void 0 === i[t]
                ? t
                : '<span class="kt-badge kt-badge--' +
                i[t].state +
                ' kt-badge--dot"></span>&nbsp;' +
                '<span class="kt-font-bold kt-font-' +
                i[t].state +
                '">' +
                i[t].title +
                "</span>";
            }
          },
          {
            targets: 7,
            className: "text-center",
            render: function (t, e, a, n) {
              var i = {
                NEW: {
                  title: "New",
                  class: "kt-badge--brand"
                },
                CHECKEDIN: {
                  title: "CheckedIn",
                  class: "kt-badge--brand"
                },
                SKIPPED: {
                  title: "Skipped",
                  class: "kt-badge--warning"
                },
                CALLING: {
                  title: "Calling",
                  class: "kt-badge--info"
                },
                SERVING: {
                  title: "Serving",
                  class: "kt-badge--metal"
                },
                DONE: {
                  title: "Done",
                  class: "kt-badge--success"
                },
                CANCEL: {
                  title: "Cancel",
                  class: "kt-badge--danger"
                }
              };
              return void 0 === i[t]
                ? t
                : '<span class="kt-badge ' +
                i[t].class +
                ' kt-badge--inline kt-badge--pill">' +
                i[t].title +
                "</span>";
            }
          },
          {
            targets: -1,
            responsivePriority: 1
          }
        ],
        tableFilter: [
          {
            title: "Tanggal Kedatangan",
            type: "datepicker"
          },
          {
            title: "Waktu Dibuat",
            type: "datepicker"
          },
          {
            title: "No Antrian",
            type: "text"
          },
          {
            title: "Display Name 1",
            type: "combobox"
          },
          {
            title: "Display Name 2",
            type: "combobox"
          },
          {
            title: "Tipe Antrian",
            type: "combobox"
          },
          {
            title: "Status",
            type: "combobox"
          },
          {
            title: "Actions",
            type: "actions"
          }
        ]
      }
    }
  },
  components: {
      DataTables
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    modalHistory: { type: String, default: "modalHistory" }
  },
  methods: {
      close() {
        $("#" + this.modalHistory).modal("hide");
        this.setShowHistory(false);
      },
    ...mapMutations({
        setShowHistory: 'UnitCallerStore/SET_SHOW_HISTORY'
    }),
    async fetch(resolve) {
        const tanggalHariIni = new Date().toISOString().split('T')
        const params = {
            "date.equals": `${tanggalHariIni[0]}`,
            "queueStatus.in": ["DONE", "SKIPPED"],
            "queueUnitId.in": this.queueDevice && this.queueDevice.queueUnits.length > 0 ?  this.queueDevice.queueUnits[0].id : '',
            "page" : 0
        }
        new Promise((resolve, reject) => {
            QueueUserRepository.get(params, resolve, reject);
        }).then(response => {
            const data = response.data;
            if (this.isLoaded) {
            var datatable = $("#" + this.tableName).DataTable();
            datatable.clear();
            datatable.rows.add(data);
            datatable.draw("full-hold");
            if (resolve !== undefined) {
                resolve(data);
            }
            } else {
                this.table.tableDataResult = data;
                this.showLoading = false;
                this.isLoaded = true;
            }
            setTimeout(() => {
            },)
        }).catch(error => {
            var msg = (error.message) ? error.message : error;
            this.isLoaded = true;
            toastr.error(msg);
        })
    },
  },
  computed: {
    ...mapState('CallerStore', {
        queueDevice: state => state.queueDevice,
    }),
    ...mapState('UnitCallerStore', {
        historyUnitQueue: state => state.historyUnitQueue
    })
  },
  mounted() {
    $("#" + this.modalHistory).modal();
    this.fetch()
  }
};
</script>

<style lang="scss" scoped>
</style>
